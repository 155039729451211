import { useState } from 'react';

import './App.css';
import QuestionView from './components/QuestionView';
import Questions from './question.json';
import ProgressBar from './components/ProgressBar';
import FormularView from './components/FormularView';

function App() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentQuestionCounter, setCurrentQuestionCounter] = useState(0);
  const [isOver, setIsOver] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [questionList, setQuestionList] = useState(Questions);
  const [questionLength, setQuestionLength] = useState(Questions.questions.length);
  const [progressBar, setProgressBar] = useState(0);
  const [animation, setAnimation] = useState(false);

  const handleQuestionSelectClick = (selected) => {
    let next = currentQuestion + 1;
    
    window.scrollTo(0, 0);

    setCurrentQuestionCounter(next);
    progress();
    saveSelectedQuestion(selected); 

    setAnimation(true);

    setTimeout(function(){
      setAnimation(false);
      if(next < questionLength){
        setCurrentQuestion(next);
      }else{
        setCurrentQuestionCounter(next + 1);
        setIsOver(true);
      }
      
      
    },500);



  
  };




  const saveSelectedQuestion = (selected) => {
    setSelectedQuestion([...selectedQuestion, selected]);
  };

  const handleQuestionBackClick = () => {
    const updateQuestions = [...selectedQuestion];

    if(currentQuestion == 1){
      setCurrentQuestion(currentQuestion -1);
      updateQuestions.splice(-1);
      setSelectedQuestion([]);
      progress(false);
      setIsOver(false);
      return;
    }

    if(currentQuestionCounter == (questionLength + 1)){
      setCurrentQuestion(questionLength - 1);
      progress(false);
      setIsOver(false);
      setCurrentQuestionCounter(questionLength);
      return;
    }

    setCurrentQuestion(currentQuestion -1);
    updateQuestions.splice(-1);
    setSelectedQuestion(updateQuestions);
    progress(false);
    setIsOver(false);
  }

  const progress = (option = true) => {
    if(option){
      const value = (currentQuestion + 1) / questionLength * 100;
      setProgressBar(value);
    }else{
      const value = (currentQuestion - 1) / questionLength * 100;
      setProgressBar(value);
    }
  }

  return (
    <div className="App">
      <>
        {!isOver &&
        <ProgressBar
          progressBar={progressBar}
          questionLength={questionLength + 1}
          currentQuestion={currentQuestion + 1}
        />
        }
      </>
      {isOver ? (
        <div className="formular-wrapper">
          <FormularView SelectedQuestion={selectedQuestion} />
          </div>
      ): (
        <QuestionView 
        Questions={questionList}
        currentQuestion={currentQuestion}
        handleQuestionSelectClick={handleQuestionSelectClick}
        handleQuestionBackClick={handleQuestionBackClick}
        Animation={animation}
        />
      )}
    </div>
  );
}

export default App;