

const apartment = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="nc_icon"  viewBox="0 0 24 24">
            <g fill="currentColor" className="nc-icon-wrapper">
                <path d="M7 10H5V1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6h-2V2H7v8z"/>
                <path d="M23 8h-9a1 1 0 0 0-1 1v13h-2v-9a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1zM7 21H4v-2h3v2zm0-4H4v-2h3v2zm13 4h-3v-2h3v2zm0-4h-3v-2h3v2zm0-4h-3v-2h3v2z"/>
            </g>
        </svg>
    )
}

export default apartment;



