import React, { useState } from 'react';
import axios from 'axios';

import "./FormularView.css";
import ProgressBar from './ProgressBar';

import Spinner from '../images/spinner.svg';
import CheckIcon from '../images/CheckIcon';

function FormularView({SelectedQuestion}) {
    const valueSelectedQuestion = SelectedQuestion.toString();
    const [isLoading, setIsLoading] = useState(false);
    const [sendDataSuccess, setSendDataSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: "",
        firma: "",
        branche: "",
        email: "",
        phone: "",
        subject: valueSelectedQuestion
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({...formData, [name]: value});
        setErrors({ ...errors, [name]: "" });
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = {};
        const formDataObj = new FormData();

        
        if (formData.name.trim() === "") {
            validationErrors.name = "Bitte ausfüllen!";
        }
        if (formData.email.trim() === "") {
            validationErrors.email = "Bitte ausfüllen!";
        }


        formDataObj.append('name', formData.name);
        formDataObj.append('firma', formData.firma);
        formDataObj.append('branche', formData.branche);
        formDataObj.append('email', formData.email);
        formDataObj.append('phone', formData.phone);
        formDataObj.append('subject', formData.subject);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        }else{
            console.log(window.location.origin);
            setIsLoading(true);
            axios({
                method: 'post',
                url: window.location.origin + '/backend/send.php',
                headers: {'Content-Type': 'multipart/form-data' },
                data: formDataObj
            }).then((response) => {
                if (response.status == '200') {
                    //document.querySelector(".success").style.cssText = "display: block; text-align: center;";
                    setSendDataSuccess(true);
                    document.body.className += ' app-end';
                    window.scrollTo(0, 0);
                }else{
                    console.log('Nein');
                }
            }).catch((error) => {
                console.log('Error submittig from:', error);
                setIsLoading(false);
            })

        }


    }

    return (
        <div className="form-wrap">
            {!sendDataSuccess && 
                <>
                    <ProgressBar />
                    <div className="question-headline">
                        Ihre Kontaktdaten
                    </div>
                </>
            }  

            <form onSubmit={handleSubmit}>
                {sendDataSuccess && <div className="success display-none">Vielen Dank für Ihre Anfrage ­- wir melden <br/>uns schnellstmöglich bei Ihnen!</div>}
                {!sendDataSuccess &&
                <>
                
                <div className="form-input-handler">
                    <div className="form-input-item form-input-item-row-1">
                        <div className="item-handler">
                            <label htmlFor="name">Vor- und Nachname*
                            {errors.name && (
                                <span className="validation-message">{errors.name}</span>
                            )}
                            </label>
                                <input 
                                    type="text"
                                    id="name"
                                    className="full-width"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                        
                        </div>
                        <div className="item-handler">
                            <label htmlFor="firma">Firma
                            </label>
                                <input 
                                    type="text"
                                    id="firma"
                                    className="full-width"
                                    name="firma"
                                    value={formData.firma}
                                    onChange={handleInputChange}
                                />
                   
                        </div>
                        <div className="item-handler">
                            <label htmlFor="branche">Branche
                            </label>
                                <input 
                                    type="text"
                                    id="branche"
                                    className="full-width"
                                    name="branche"
                                    value={formData.branche}
                                    onChange={handleInputChange}
                                />
                         
                        </div>
                    </div>

                    <div className="form-input-item form-input-item-row-2">
                        <div className="item-handler">
                            <label htmlFor="email">E-Mail*:
                            {errors.email && (
                                <span className="validation-message">{errors.email}</span>
                            )}
                            </label>
                            <div className="item-handler-input max-input">
                                <input type="email" id="email" className="full-width" name="email" 
                                    value={formData.email} 
                                    onChange={handleInputChange} 
                                />
                            </div>
                        </div>
                        <div className="item-handler">
                            <label htmlFor="phone">
                            Telefonnummer:
                            </label>
                            <div className="item-handler-input">
                                <input type="text" id="name" className="full-width" name="phone" 
                                    value={formData.phone} 
                                    onChange={handleInputChange} 
                                />            
                            </div>
                        </div>
                    </div>
                    <div className="form-copy">
                        <p>
                            Mit der Eingabe und Absendung Ihrer Daten erklären Sie sich einverstanden, dass wir Ihre Angaben zum Zwecke der Beantwortung Ihrer Anfrage und etwaiger Rückfragen entgegennehmen, zwischenspeichern und auswerten. Sie können diese Einwilligung jederzeit widerrufen. Siehe auch unsere Datenschutzhinweise.  *
                        </p>
                    </div>
                    


                </div>

                <div className="submit-handler">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="next-btn"
                    >
                        
                        {isLoading ? (
                        <div className="loader-html">
                        {!sendDataSuccess ? 
                            <img alt="Loader " src={Spinner} className="loader-img" />
                            : 
                            <i>
                                <CheckIcon/>
                            </i>
                            }
                        </div>
                        ) : (
                        "Jetzt abschicken"
                        )}
                    
                    </button>
                </div>
                
                </>            
                }
            </form>
        </div>
    )
}

export default FormularView
