import React from 'react'
import "./ProgressBar.css";

function ProgressBar({progressBar = 100, questionLength = 5, currentQuestion = 5}) {
    return (
        <div className="progress-wrap">
            <div className="progress-steps">
                <p>Schritt {currentQuestion} von {questionLength}</p>
            </div>
            <div className="progress-bar">
                <div className="progress-bar-inner"style={{width: progressBar + '%'}}></div>
        
            </div>
        </div>
    )
}

export default ProgressBar
