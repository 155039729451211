const home = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="nc_icon" viewBox="0 0 24 24">
            <path fill="currentColor" d="m22.74 12.327-10-11a1 1 0 0 0-1.48 0l-10 11A1.002 1.002 0 0 0 2 14h2v9a1 1 0 0 0 1 1h5v-6h4v6h5a1 1 0 0 0 1-1v-9h2a.999.999 0 0 0 .74-1.673z" className="nc-icon-wrapper"/>
        </svg>
    )
}

export default home;



